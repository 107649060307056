body, html {
 height: 100%;   
 margin: 0;
 font-family: 'Courier New', Courier, monospace;
 font-weight: 900;
 background-color: aliceblue;
 box-sizing: border-box;
}

.main-container {
    
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    @media (max-width: 472px) {
        width: 100vw;
    }

    .main-flex-items {
        
        padding: 30px 50px;
        margin: 20px;
        border-radius: 20px;
        flex: 1;
        background-color: rgba(255,255,255, 0.9);
        @media (max-width: 472px) {
            padding: 0;
        }

        &.right {

            z-index: 5;
            overflow-y: auto;
            overflow-x: hidden;

            .chat-container {

                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;    

                .messages {
                    flex: 1;
                    word-break: break-all;
                }

                             
            }       
        }
    }
}

.form {
    flex: 0;
    margin: 5px 20px 20px 20px;
    z-index: 4;
    
    form {
        display: flex;
        justify-content: space-between;  
        height: 100px;
        
        input[type="text"] {
            background-color: rgba(255,255,255, 0.9);
            flex: 1;
            padding: 10px;
            font-size: 1.2rem;
            margin-right: 10px;
            border-radius: 10px;
            border: none;
            @media (max-width: 472px) {
                width: 20px;
            }

            &:focus {
                outline: none;
                border: 3px solid #146A7C;
            }
        }

        input[type="submit"] {
            background-color: rgba(255,255,255, 0.9);
            border: none;
            border-radius: 10px;
            width: 70px;
            @media (max-width: 472px) {
                width: 50px;
            }
            
            &:hover {
                cursor: pointer;
            }
        }
    }
}   

ul {
    flex-direction: column;
    margin: 0;
    padding: 0;
        
    li {
        list-style: none;
        padding: 10px;
        display: flex;
        flex-direction: column;
        
        .avatar {
            padding: 0 10px;
            @media (max-width: 472px) {
                padding: 0 4px;
            }

            svg {
                @media (max-width: 472px) {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .outer {
            padding: 10px;
            background-color: #c5e4eb;
            border-radius: 10px;

            &.messss {
                background-color: #d0dbf7
            }
        }

        &.me {
            flex-direction: row-reverse;
            text-align: right;
            margin-left: auto;
        }

        &:not(.me) {
            margin-right: auto;
        }

        .date {
            padding: 0;
            font-size: 10px;
        }
        
        .text {
            padding: 0;
            font-size: 1.5rem;
            padding-bottom: 4px;
            @media (max-width: 472px) {
                font-size: 1rem;
            }
        }

        .username {
            padding-bottom: 5px;
            font-size: 0.8rem;
        }

        .message-content {
            display: flex;
            flex-direction: row;
        }

    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    @media (max-width: 472px) {
        display: none;
    }
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

